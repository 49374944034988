import request from '@/utils/request'

// 广告数据概览字典项
export function getFields(accountId) {
  return request({
    url: `/dashboard/fb-fields/${accountId}`,
    method: 'get'
  })
}
// 顶部卡片数据
export function getOverviewDatas(data) {
  return request({
    url: '/dashboard/overview',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}
// 中间折线图数据
export function getOverviewDetails(data) {
  return request({
    url: '/dashboard/detail',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}
// 获取细分数据
export function breakdown(data) {
  return request({
    url: '/dashboard/breakdown',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}