<template>
  <div class="wrap pr">
    <div id="creative-chart" style="height:320px;"></div>
    <div
      v-if="nodata"
      class="pa dp-flex align-item-c justify-c"
      style="top:0;left:0;bottom:0;right:0;background-color: #00000008"
    >
      待开发
    </div>
  </div>
</template>

<script>
import { setAxisMaxMin } from "./tool";
export default {
  props: {
    selFields: {
      type: Array,
      default: () => []
    },
    legends: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    themeColor: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        title: {
          text: "创意类型",
          left: 50,
          textStyle: {
            color: "rgba(0,0,0,.85)",
            fontWeight: 500
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          alignWithLabel: true,
          data: []
        },
        yAxis: {},
        series: []
      },
      colors: ["#247AE6", "#CED4DE"],
      nodata: true
    };
  },
  computed: {},
  methods: {
    updateChart() {
      this.myChart = this.$echarts.init(
        document.getElementById("creative-chart")
      );
      this.myChart.setOption(this.chartOption, true);
    },
    createTooltip(data, arr, legends, colors) {
      let obj = {
        trigger: "axis",
        extraCssText: `background-color:#fff;color:${this.themeColor};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`,
        formatter: params => {
          // console.log("tooltip-params", params);
          let title = params[0].axisValueLabel;
          let cur = data.find(d => d.title == title);
          let spendKey = ["spend", "industry_spend"];
          let result = "";
          result += `<p>${title}<p><p>${arr[0].label}<p>`;
          params.forEach((item, i) => {
            result += `<p><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
              colors[i]
            }"></span>${item.seriesName}：${
              item.data ? item.data : "--"
            }<span>${arr[1].label}：${cur[spendKey[i]]}</span></p>`;
          });
          return result;
        }
      };
      return obj;
    },
    createyAxis(data, arr) {
      let _arr = [];
      arr.forEach((item, i) => {
        let gap = i == 0 ? 50 : 40;
        let obj = {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        };
        _arr.push(obj);
      });
      return _arr;
    },
    createSeries(data, arr, legends) {
      let _arr = [];
      legends.forEach((item, i) => {
        let key = i == 0 ? arr[0].value : "industry_" + arr[0].value;
        let _data = data.map(d => {
          return d[key] && d[key].value ? d[key].value : 0;
        });
        let obj = {
          symbol: "none",
          name: `${item}${arr[0].label}数据`,
          type: "bar",
          // data: _data,
          data: [],
          yAxisIndex: i
        };
        _arr.push(obj);
      });
      // console.log("createSeriesData", _arr);
      return _arr;
    },
    createOption(data, selArr, legends, colors) {
      this.chartOption.grid = {
        left: 50,
        bottom: 50,
        top: 50,
        right: 50,
        containLabel: true
      };
      this.chartOption.color = this.colors;
      this.chartOption.legend = {
        data: legends.map(item => `${item}${selArr[0].label}数据`),
        icon: "circle",
        bottom: 0,
        itemGap: 20,
        itemWidth: 10,
        itemHeight: 10
      };
      this.chartOption.tooltip = this.createTooltip(
        data,
        selArr,
        legends,
        colors
      );
      // this.chartOption.xAxis.data = data.map(item => item.title);
      this.chartOption.xAxis.data = ["图片", "视频", "轮播", "其他"];
      this.chartOption.yAxis = this.createyAxis(data, legends);
      this.chartOption.series = this.createSeries(data, selArr, legends);
      // 设置坐标轴最大最小值和间隔
      setAxisMaxMin("yAxis", this.chartOption, {
        noDataSplitNum: 5,
        hasDataSplitNum: 5
      });
      // 无数据时文字提示
      this.nodata = this.chartOption.series.every(item =>
        item.data.every(d => d == "0" || !d)
      );
      // console.log("this.creativechartOption", this.chartOption);
      this.updateChart();
    }
  },
  mounted() {
    //图表初始化
    this.createOption(
      this.chartData,
      this.selFields,
      this.legends,
      this.colors
    );
  },
  watch: {
    chartData: {
      handler(val) {
        //图表初始化
        this.createOption(val, this.selFields, this.legends, this.colors);
      },
      deep: true
    }
  }
};
</script>

<style lang='scss'>
@import "@/styles/commonly";
#creative-chart {
  margin-top: 20px;
}
</style>